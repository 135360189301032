<template>
  <div class="container">
    <!-- -->
    <!-- 服务搜索 -->
    <!-- 设备选择 -->
    <!-- 设置预约时间段 -->
    <!-- 预约时间段设置 价格，是否可约-->

    <el-form>
      <el-form-item label="场馆" required>
        <venueSelect
          ref="ven"
          :venueid.sync="venue_id"
          @change="changeVenue"
        ></venueSelect>
      </el-form-item>
      <!-- <el-form-item label="服务" required>
        <serviceSelect
          ref="ser"
          :condition="{ venue_id }"
          @change="changeService"
        ></serviceSelect>
      </el-form-item> -->
      <el-form-item label="排期类型" required>
        <el-radio v-model="appoint_type" label="week_day">按周几</el-radio>
        <el-radio v-model="appoint_type" label="time">按时间段</el-radio>
      </el-form-item>

      <el-form-item label="设置时间段" required>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <div>服务预约排期信息生效时间段</div>
      </el-form-item>
      <el-form-item label="开放场次" required>
        <el-button type="success" @click="openTimeMold">添加场次</el-button>
      </el-form-item>
      <el-form-item label="开放设备" required>
        <el-checkbox-group v-model="changeDevices" @change="deviceChange">
          <el-checkbox
            :label="device"
            v-for="device in devices"
            :key="device.id"
            >{{ device.name }}</el-checkbox
          >
        </el-checkbox-group>
        <div v-if="devices.length == 0">暂无设备</div>
      </el-form-item>

      <el-form-item label="可约时间" v-if="appoint_type == 'week_day'" required>
        <el-checkbox-group v-model="changeWeek">
          <el-checkbox :label="w.value" v-for="w in weekInfo" :key="w.value">{{
            w.name
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <appointmentTable
      ref="appt"
      :datas="appointInfo"
    ></appointmentTable>

    <div class="m-t20">
      <el-button type="success" @click="submitData">创建预约设置</el-button>
    </div>

    <el-dialog
      title="设施场次时间设置"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form>
        <el-form-item label="时间段">
          <el-time-select
            placeholder="起始时间"
            v-model="startTime"
            :picker-options="{
              start: '05:00',
              step: '01:00',
              end: '23:00',
            }"
          >
          </el-time-select>
          至
          <el-time-select
            placeholder="结束时间"
            v-model="endTime"
            :picker-options="{
              start: '05:00',
              step: '01:00',
              end: '23:00',
              minTime: startTime,
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="单场时间">
          <el-radio
            v-model="mold"
            v-for="(item, index) in timeMold"
            :key="index"
            :label="index"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTimeInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import serviceSelect from "@/components/select/serviceSelect.vue";
import venueSelect from "@/components/select/venueSelect.vue";
import appointmentTable from "../components/appointmentTable.vue";

export default {
  components: {
    serviceSelect,
    venueSelect,
    appointmentTable,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() <=
            new Date(new Date().toLocaleDateString()).getTime() - 10
          );
        },
      },
      weekInfo: [
        { name: "周一", value: 1 },
        { name: "周二", value: 2 },
        { name: "周三", value: 3 },
        { name: "周四", value: 4 },
        { name: "周五", value: 5 },
        { name: "周六", value: 6 },
        { name: "周七", value: 7 },
      ],
      venue_id: "",
      venue: {},
      appoint_type: "time",
      dateRange: [],
      startTime: "",
      endTime: "",
      changeWeek: [],
      devices: [],
      changeDevices: [],
      timeMold: [
        {
          name: "1小时",
          value: 1,
        },
        {
          name: "90分钟",
          value: 1.5,
        },
        {
          name: "2小时",
          value: 2,
        },
        {
          name: "整个时段",
          value: 0,
        },
      ],
      mold: 0,
      timeData: [],
      appointInfo: [],
      dialogVisible:false
    };
  },

  mounted() {
    if (this.$route.query.vid) {
      this.venue_id = this.$route.query.vid;
      this.getVenueInfo();
    }
  },

  methods: {
    addTimeInfo() {
      if (this.endTime == this.startTime) {
        return;
      }
      let hourTemp = 60 * 60 * 1000;
      let s = this.timeToTemp(`2024-01-01 ${this.startTime}:00`);
      let e = this.timeToTemp(`2024-01-01 ${this.endTime}:00`);

      this.timeData = this.timeData.filter((v) => {
        if (
          this.timeToTemp(`2024-01-01 ${v.s}:00`) < e &&
          this.timeToTemp(`2024-01-01 ${v.s}:00`) >= s
        ) {
          return false;
        } else if (
          this.timeToTemp(`2024-01-01 ${v.e}:00`) <= e &&
          this.timeToTemp(`2024-01-01 ${v.e}:00`) > s
        ) {
          return false;
        }
        return true;
      });
      let timeData = [];
      if (this.timeMold[this.mold].value === 0) {
        let item = {
          s: this.startTime,
          e: this.endTime,
          v: 1,
          m:
            Number(this.endTime.split(":")[0]) -
            Number(this.startTime.split(":")[0]),
        };
        timeData.push(item);
      } else {
        let i = s;
        let condition = s;
        while (condition <= e) {
          let start = new Date(i).Format("hh:mm");
          i += hourTemp * this.timeMold[this.mold].value;
          let end = new Date(i).Format("hh:mm");
          let item = {
            s: start,
            e: end,
            v: 1,
            m: this.timeMold[this.mold].value,
          };
          timeData.push(item);
          condition = i + hourTemp * this.timeMold[this.mold].value;
        }
      }

      this.timeData = this.timeData.concat(timeData);
      let info = [];
      this.changeDevices.forEach((item) => {
        let data = this.timeData;
        info.push({
          device: item,
          data,
        });
      });
      this.appointInfo = info;
      this.dialogVisible = false;
    },
    zeroFill(n) {
      return n < 10 ? "0" + n : n;
    },
    openTimeMold() {
      this.dialogVisible = true;
    },
    getVenueInfo() {
      let url = "/user/mechanism/venue/findById";
      this.$axios({
        url,
        params: { id: this.venue_id },
      }).then((res) => {
        this.changeVenue(res.data.data);
        this.$refs.ven.setValue(res.data.data);
      });
    },
    deviceChange(v) {
       console.log(v);
				if(this.timeData.length == 0){
					return
				}
				let info = []
				this.changeDevices.forEach((item) => {
					let data = this.timeData;
					info.push({
						device: item,
						data
					});
				});
				this.appointInfo = info
			},
    //提交
    submitData() {
      if (!this.venue_id) {
        this.$message("请选择场馆");
        return;
      }
      if (this.dateRange.length != 2) {
        this.$message("请选择生效时间范围");
        return;
      }
      let appointInfo = this.$refs.appt.getAppointTemplate();
      if (appointInfo.length == 0) {
        this.$message("未设置预约时间信息");
        return;
      }
      let data = {
        type: this.appoint_type,
        venue_id: this.venue_id,
        start_time: this.dateRange[0] + " 00:00:00",
        end_time: this.dateRange[1] + " 23:59:59",
        data: appointInfo,
      };
      if (this.appoint_type == "week_day") {
        if (this.changeWeek.length == 0) {
          this.$message("请选择可约时间");
          return;
        }
        data.weeks = this.changeWeek;
      }
      let url = "user/serviceAppointTemp/createTemplate";
      this.$axios({
        url,
        method: "post",
        data: data,
      }).then((res) => {
        this.$message("排期成功");
        this.dateRange = [];
        this.changeDevices = [];
        this.devices = [];
        this.startTime = "";
        this.endTime = "";
      });
    },

    changeVenue(item) {
      this.venue = item;
      let url = "user/venue/device/query";
      this.$axios({
        url,
        params: {
          venue_id: this.venue_id,
          status: 0,
        },
      }).then((res) => {
        this.devices = res.data.data;
        this.changeDevices = res.data.data;
      });
    },
  },
};
</script>